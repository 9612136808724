import { Row, Spin } from 'antd'

function Loading() {
  return (
    <Row align="middle" justify="center" style={{ height: '100%' }}>
      <Spin size="large" />
    </Row>
  )
}

export default Loading
