import ReactDOM from 'react-dom'
import App from './App'
import './index.less'
import { token } from './store/TokenContext'

// if (import.meta.env.MODE === 'development') {
//   import('src/mock')
// }

// 读取存储的token
token.getToken()

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (import.meta.env.MODE === 'development') {
  import('./reportWebVitals').then((reportWebVitals) => reportWebVitals.default())
}
