import { createContext } from 'react'
import { cookieGet, cookieSet, cookieRemove } from 'src/utilities'

/**
 * 登录Token
 */
export const token = {
  token: '',
  expire: '',
  tokenRefresh: '',
  refreshExpire: '',
  getToken() {
    this.token = cookieGet('token')
    this.expire = cookieGet('expire')
    this.tokenRefresh = cookieGet('tokenRefresh')
    this.refreshExpire = cookieGet('refreshExpire')
  },
  setToken(data: any) {
    this.token = data.token
    this.expire = data.expire
    this.tokenRefresh = data.token_refresh
    this.refreshExpire = data.refresh_expire
    cookieSet('token', data.token)
    cookieSet('expire', data.expire)
    cookieSet('tokenRefresh', data.token_refresh)
    cookieSet('refreshExpire', data.refresh_expire)
  },
  refreshToken(data: any) {
    this.token = data.token
    this.expire = data.expire
    cookieSet('token', data.token)
    cookieSet('expire', data.expire)
  },
  removeToken() {
    this.token = ''
    this.expire = ''
    this.tokenRefresh = ''
    this.refreshExpire = ''
    cookieRemove('token')
    cookieRemove('expire')
    cookieRemove('tokenRefresh')
    cookieRemove('refreshExpire')
  }
}

const TokenContext = createContext(token)

export default TokenContext
