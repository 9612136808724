import { DictProps } from 'src/api'

/**
 * 获取cookie
 *
 * @export
 * @param {string} key - 键名
 * @param {boolean} raw - 是否编码
 * @returns 值
 */
export function cookieGet(key: string, raw: boolean = true) {
  let arr
  let reg = new RegExp('(^| )' + key + '=([^;]*)(;|$)')
  if ((arr = document.cookie.match(reg))) {
    if (raw) {
      return decodeURIComponent(arr[2])
    }
    return arr[2]
  }
  return ''
}

/**
 * 设置cookie
 *
 * @export
 * @param {string} key - 键名
 * @param {*} value - 值
 * @param {number} days - 天数
 * @param {boolean} raw - 是否编码
 */
export function cookieSet(key: string, value: any, days: number = 30, raw: boolean = true) {
  var exp = new Date()
  var val = value
  if (raw) {
    val = encodeURIComponent(value)
  }
  exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = `${key}=${val};expires=${exp.toUTCString()};domain=${import.meta.env.VITE_ROOT_DOMAIN};path=/`
}

/**
 * 删除cookie
 *
 * @export
 * @param {string} key - 键名
 */
export function cookieRemove(key: string) {
  var exp = new Date()
  exp.setTime(exp.getTime() - 1000)
  var value = cookieGet(key)
  if (value != null) {
    document.cookie = `${key}=;expires=${exp.toUTCString()};domain=${import.meta.env.VITE_ROOT_DOMAIN};path=/`
  }
}

/**
 * 获取存储数据
 *
 * @export
 * @param {string} key - key
 * @returns
 */
export function storageGet(key: string) {
  return localStorage.getItem(key)
}

/**
 * 设置存储数据
 *
 * @export
 * @param {string} key - key
 * @param {string} value - value
 */
export function storageSet(key: string, value: string | number) {
  if (typeof value === 'number') {
    value = `${value}`
  }
  localStorage.setItem(key, value)
}

/**
 * 移除存储数据
 *
 * @export
 * @param {string} key - key
 */
export function storageRemove(key: string) {
  localStorage.removeItem(key)
}

/**
 * 根据正则key清除存储数据，不传清除所有
 *
 * @export
 * @param {RegExp} [regex] - 正则表达式key
 */
export function storageClear(regex?: RegExp) {
  if (regex) {
    Object.keys(localStorage).forEach((key) => {
      if (regex.test(key)) {
        localStorage.removeItem(key)
      }
    })
  } else {
    localStorage.clear()
  }
}

/**
 * 过滤掉搜索条件中的假值[false、null、undefined、0、'']
 *
 * @export
 * @param {{ [k: string]: any }} params - 参数
 * @returns
 */
export function filterParams(params: { [k: string]: any }) {
  let condition: { [k: string]: any } = {}
  for (const key in params) {
    let value = params[key]

    // 只有值为真时才加入搜索条件
    if (value) {
      condition[key] = value
    }
  }
  return condition
}

/**
 * token是否过期
 *
 * @export
 * @returns
 */
export function isExpired() {
  let expire = cookieGet('expire')
  if (expire) {
    let time = parseInt(expire, 10)
    let now = Math.floor(new Date().getTime() / 1000)
    return now > time
  }
  return true
}

/**
 * refreshToken是否过期
 *
 * @export
 * @returns boolean
 */

export function isRefreshExpired() {
  let refreshExpire = cookieGet('refreshExpire')
  if (refreshExpire) {
    let time = parseInt(refreshExpire, 10)
    let now = Math.floor(new Date().getTime() / 1000)
    return now > time
  }
  return true
}

/**
 * 返回类型化后的值
 *
 * @param {string} value 字符串值
 * @returns 区分类型的值
 */
function buildValue(value: string) {
  if (/^\s*$/.test(value)) {
    return null
  }
  if (/^(true|false)$/i.test(value)) {
    return value.toLowerCase() === 'true'
  }
  if (isFinite(Number(value))) {
    return parseFloat(value)
  }
  if (isFinite(Date.parse(value))) {
    return new Date(value)
  }
  return value
}

/**
 * 获取url所有参数对象
 *
 * @export
 * @param {string} str  url地址
 * @returns 键值对
 */
export function getUrlParams(str: string) {
  let obj: any = {}
  let item: any[] = []
  if (str.indexOf('?') !== -1) {
    let search = str.split('?')[1]
    item = search.split('&')
  } else {
    item = str.split('&')
  }
  for (let i = 0, j = item.length; i < j; i++) {
    let el = item[i].split('=')
    obj[decodeURIComponent(el[0])] = j > 1 ? buildValue(decodeURIComponent(el[1])) : ''
  }
  return obj
}

/**
 * 字典数组转对象
 *
 * @export
 * @param {DictProps[]} list - 字典数组
 * @returns
 */
export function array2Object(list: DictProps[]) {
  let obj: { [k: string]: string } = {}
  list.forEach((el) => {
    obj[el.id] = el.name
  })
  return obj
}
