import { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import { CSPConfig } from 'antd/es/config-provider'
import Loading from 'src/components/Loading'
import './App.less'

const csp: CSPConfig = {
  nonce: "default-src 'self' *.mlplat.com"
}

let Signin = lazy(() => import('src/views/Sign/Signin'))
let Signup = lazy(() => import('src/views/Sign/Signup'))
let BasicLayout = lazy(() => import('src/components/BasicLayout'))

function App() {
  return (
    <Router>
      <ConfigProvider locale={zhCN} csp={csp}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/signin" component={Signin} />
            <Route exact path="/signup" component={Signup} />
            <Route path="/" component={BasicLayout} />
          </Switch>
        </Suspense>
      </ConfigProvider>
    </Router>
  )
}

export default App
